<template>
  <div
    :id="elementId"
    ref="component"
    class="chart-wrapper"
  >
    <pie-chart
      :options="options"
      :styles="{
        width: `${contentHeight}px`,
      }"
      :chart-data="chartDataSource"
    />
  </div>
</template>

<style scoped>
.chart-wrapper {display: flex; align-items: center; justify-content: center; height: 100%}
</style>

<script>
import PieChart from '@/utils/charts/PieChart.js';

export default {
  name: 'chart2',
  components: {PieChart},
  props: {
    i: {
      type: Number,
      default: 0,
    },
    uuid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      contentHeight: 0,
      options: {
        responsive: true,
        legend: {display: false},
      },
    };
  },
  watch: {
    async uuid() {
      await this.$nextTick();
      this.resize();
    }
  },
  async mounted() {
    await this.$nextTick();
    this.resize();
    let self = this;
    window.onresize = () => self.resize();
  },
  computed: {
    elementId() {
      return `content-wrapper-${this.i}`;
    },
    chartDataSource() {
      const dummyData = [
        {
          name: '테스트1',
          value: 11,
          color: '#9FC93C',
        },
        {
          name: '테스트2',
          value: 6,
          color: '#A566FF',
        },
        {
          name: '테스트3',
          value: 8,
          color: '#D9418C',
        },
        {
          name: '테스트4',
          value: 4,
          color: '#D5D5D5',
        },
      ];
      return {
        labels: dummyData?.map(data => data.name),
        datasets: [
          {
            data: dummyData?.map(data => data.value),
            backgroundColor: dummyData?.map(data => data.color),
          }
        ]
      };
    },
  },
  methods: {
    resize() {
      const contentWrapper = document.getElementById(this.elementId);
      this.contentHeight = contentWrapper?.getBoundingClientRect()?.height;
    },
  },
};
</script>
