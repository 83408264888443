<template>
  <div class="content-wrapper">
    <grid-layout
      :layout.sync="viewLayout"
      :class="[isReduced && 'reduce']"
      :col-num="12"
      :row-height="30"
      :is-draggable="!isReduced"
      :is-resizable="!isReduced"
      :vertical-compact="true"
      :use-css-transforms="true"
      @layout-created="layoutCreatedEvent"
      @layout-before-mount="layoutBeforeMountEvent"
      @layout-mounted="layoutMountedEvent"
      @layout-ready="layoutReadyEvent"
      @layout-updated="layoutUpdatedEvent"
    >
      <grid-item
        v-for="(item, index) in viewLayout" :key="index"
        :class="[item.reduce && 'reduce']"
        :static="item.static"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        drag-allow-from=".vue-draggable-handle"
        @move="moveEvent"
        @moved="movedEvent"
        @resize="resizeEvent"
        @resized="resizedEvent"
        @container-resized="containerResizedEvent"
      >
        <div class="header">
          <div class="title">
            {{ item.title }}
          </div>
          <div
            v-if="!isReduced"
            class="item"
            @click="pin(item.i)"
          >
            <div :class="item.static ? 'pin' : 'unpin'"/>
          </div>
          <div
            v-if="!isReduced"
            :class="['item', item.static && 'translucent']"
          >
            <div class="move vue-draggable-handle"/>
          </div>
<!--          <div-->
<!--            class="item"-->
<!--            @click="reduce(item.i)"-->
<!--          >-->
<!--            <div :class="isReduced ? 'reduce' : 'extend'"/>-->
<!--          </div>-->
        </div>
        <div class="content">
          <div
            :is="item.is"
            :i="item.i"
            :uuid="item.uuid"
            :is-reduced="isReduced"
          />
        </div>
      </grid-item>
    </grid-layout>
  </div>
</template>

<style scoped>
/* grid layout */
.vue-grid-layout {min-height: 100%; background-color: #fafafa; overflow: scroll}
.vue-grid-layout.reduce {position: relative; overflow: hidden}
/* grid item */
.vue-grid-item {display: flex; flex-direction: column; overflow: hidden}
.vue-grid-item.reduce {width: calc(100% - 20px) !important; height: calc(100% - 20px) !important; position: absolute; top: 0; left: 0; z-index: 1}
.vue-grid-item > .header {display: flex; border-bottom: 1px solid #ccc}
.vue-grid-item > .header > .title {display: flex; flex: 1; align-items: center; height: 29px; line-height: 32px; padding: 0 0 0 8px; color: #222; font-family: 'NanumSquare-Bold', serif; font-size: 14px}
.vue-grid-item > .header > .item {display: flex; align-items: center; justify-content: center; width: 29px; height: 29px; border-left: 1px solid #ccc; cursor: pointer}
.vue-grid-item > .header > .item.translucent {opacity: .6}
.vue-grid-item > .header > .item > .pin {background: url(../../assets/images/layout/pin-button.png) center; width: 29px; height: 29px}
.vue-grid-item > .header > .item > .unpin {background: url(../../assets/images/layout/unpin-button.png) center; width: 29px; height: 29px}
.vue-grid-item > .header > .item > .move {background: url(../../assets/images/layout/move-button.png) center; width: 29px; height: 29px; cursor: move}
.vue-grid-item > .header > .item.translucent > .move {cursor: not-allowed}
.vue-grid-item > .header > .item > .extend {background: url(../../assets/images/layout/sideRight-header-extend-button.png) center; width: 29px; height: 29px; cursor: zoom-in}
.vue-grid-item > .header > .item > .reduce {background: url(../../assets/images/layout/sideRight-header-reduce-button.png) center; width: 29px; height: 29px; cursor: zoom-out}
.vue-grid-item > .content {height: 100%}
.vue-grid-item:not(.vue-grid-placeholder) {border: 1px solid #ccc; background-color: #fff}
.vue-grid-item .resizing {opacity: .5}
.vue-grid-item .no-drag {height: 100%; width: 100%}
/* scroll bar */
::-webkit-scrollbar,
::-webkit-scrollbar-corner {
  width: 7px;
  height: 7px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  border-radius: 500rem;
  background-color: rgba(0, 0, 0, .25);
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {background-color: rgba(0, 0, 0, .5)}
</style>

<script>
/*
 대시보드 레이아웃
 vue-grid-layout - ver. 2.3.12 (https://jbaysolutions.github.io/vue-grid-layout)

 - 상위 대시보드 레이아웃 라이브러리 참고하여 개발함.

 {
   title: '부별 매출 현황', // 컴포넌트 제목
   name: 'Chart1', // 컴포넌트 이름 (import 한 것과 일치해야 함.)
   class: '', // 컴포넌트 css class
   x: 0, // 컴포넌트 - X 좌표
   y: 0, // 컴포넌트 - Y 좌표
   w: 2, // 컴포넌트 너비 - Width
   h: 5, // 컴포넌트 높이 - Height
   i: 0, // 컴포넌트 index (다른 컴포넌트와 중복되면 안됨.)
   reduce: false, // 컴포넌트 기본값 확장 여부
   static: false, // 컴포넌트 기본값 고정(핀) 여부
 }

 TODO: 1) ./components 에 차트 컴포넌트 개발.
       2) components 가 아닌 COMPONENTS static 변수에 컴포넌트 삽입.
       3) DASHBOARD_LAYOUT_INIT static 변수에 제목, 컴포넌트 이름, 위치, 크기 등 지정.
       4) DASHBOARD_LAYOUT_INIT 값이 바뀌면 아래 버전 DASHBOARD_LAYOUT_VERSION 변수 업데이트 필수
 */
import {
  GridLayout,
  GridItem,
} from 'vue-grid-layout';
import FCalendar from './components/calendar';
import Chart1 from './components/chart1';
import Chart2 from './components/chart2';
import Chart3 from './components/chart3';

const COMPONENTS = {
  FCalendar,
  Chart1,
  Chart2,
  Chart3,
};
const DASHBOARD_LAYOUT_VERSION = 2; // TODO : DASHBOARD_LAYOUT_INIT 값이 바뀌면 아래 버전 DASHBOARD_LAYOUT_VERSION 변수 업데이트 필수
const DASHBOARD_LAYOUT_INIT = [
  {
    title: '부별 매출 현황',
    name: 'Chart1',
    class: '',
    x: 0,
    y: 0,
    w: 2,
    h: 5,
    i: 0,
    reduce: false,
    static: false,
  },
  {
    title: '차트 테스트',
    name: 'Chart2',
    class: '',
    x: 0,
    y: 6,
    w: 2,
    h: 5,
    i: 1,
    reduce: false,
    static: false,
  },
  {
    title: '차트 테스트',
    name: 'Chart1',
    class: '',
    x: 0,
    y: 12,
    w: 2,
    h: 5,
    i: 2,
    reduce: false,
    static: false,
  },

  {
    title: '캘린더',
    name: 'FCalendar',
    x: 2,
    y: 0,
    w: 8,
    h: 15,
    i: 3,
    reduce: true, // TODO : 대시보드 화면 열 때 캘린더만 보이도록 임시 설정. => 기본값 TRUE
    static: false,
  },

  {
    title: '차트 테스트',
    name: 'Chart3',
    class: '',
    x: 10,
    y: 0,
    w: 2,
    h: 5,
    i: 4,
    reduce: false,
    static: false,
  },
  {
    title: '차트 테스트',
    name: 'Chart3',
    class: '',
    x: 10,
    y: 0,
    w: 2,
    h: 5,
    i: 5,
    reduce: false,
    static: false,
  },
  {
    title: '차트 테스트',
    name: 'Chart1',
    class: '',
    x: 10,
    y: 0,
    w: 2,
    h: 5,
    i: 6,
    reduce: false,
    static: false,
  },

  {
    title: '차트 테스트',
    name: 'Chart1',
    class: '',
    x: 7,
    y: 15,
    w: 3,
    h: 6,
    i: 7,
    reduce: false,
    static: false,
  },
];

export default {
  name: 'dashboard',
  components: {
    GridLayout,
    GridItem,
    // TODO : (중요) 각종 컴포넌트는 상위 COMPONENTS 변수에 import
  },
  data() {
    return {
      layout: [],
    };
  },
  async created() {
    const layout = JSON.parse(localStorage.getItem(this.dashboardLayoutKey));
    this.layout = (layout || DASHBOARD_LAYOUT_INIT)
      ?.map(item => {
        item.is = item.name && COMPONENTS[item.name];
        item.uuid = Math.random() * 10000;
        return item;
      });
  },
  computed: {
    dashboardLayoutKey() {
      return `DASHBOARD-LAYOUT-${DASHBOARD_LAYOUT_VERSION}`;
    },
    viewLayout() {
      const reducedComponent = this.layout?.find(item => item.reduce);
      console.log(this.isReduced, "this.isReduced");
      return this.isReduced
        ? [{
            ...reducedComponent,
            x: 0,
            y: 0,
          }]
        : this.layout;
    },
    isReduced() {
      return !!(this.layout?.find(item => item.reduce));
    },
  },
  methods: {
    layoutCreatedEvent(newLayout) {
      console.log('Created layout: ', newLayout);
    },
    layoutBeforeMountEvent(newLayout) {
      console.log('beforeMount layout: ', newLayout);
    },
    layoutMountedEvent(newLayout) {
      console.log('Mounted layout: ', newLayout);
    },
    layoutReadyEvent(newLayout) {
      console.log('Ready layout: ', newLayout);
    },
    layoutUpdatedEvent(newLayout) {
      console.log('Updated layout: ', newLayout);
      this.saveToLocalStorage();
    },
    moveEvent(i, x, y) {
      const msg = 'MOVE i=' + i + ', X=' + x + ', Y=' + y;
      console.log(msg);
    },
    movedEvent(i, x, y) {
      const msg = 'MOVED i=' + i + ', X=' + x + ', Y=' + y;
      console.log(msg);
    },
    resizeEvent(i, height, width, heightPx, widthPx) {
      const msg = 'RESIZE i=' + i + ', H=' + height + ', W=' + width + ', H(px)=' + heightPx + ', W(px)=' + widthPx;
      console.log(msg);
    },
    resizedEvent(i, x, y, heightPx, widthPx) {
      const msg = 'RESIZED i=' + i + ', X=' + x + ', Y=' + y + ', H(px)=' + heightPx + ', W(px)=' + widthPx;
      console.log(msg);
      const findIndex = this.layout?.findIndex(item => item.i === i);
      if (findIndex !== null) {
        this.reduce(findIndex, false);
      }
    },
    containerResizedEvent(i, height, width, heightPx, widthPx) {
      const msg = 'CONTAINER RESIZED i=' + i + ', H=' + height + ', W=' + width + ', H(px)=' + heightPx + ', W(px)=' +
          widthPx;
      console.log(msg);
    },
    async pin(i) {
      const findIndex = this.layout?.findIndex(item => item.i === i);
      this.layout[findIndex].static = !this.layout[findIndex].static;
      this.$forceUpdate();
      this.saveToLocalStorage();
    },
    async reduce(i, isReduce = true) {
      const findIndex = this.layout?.findIndex(item => item.i === i);
      this.layout[findIndex].uuid = Math.random() * 10000;
      if (isReduce) {
        this.layout[findIndex].reduce = !this.layout[findIndex].reduce;
      }
      this.$forceUpdate();
      this.saveToLocalStorage();
    },
    saveToLocalStorage() {
      // TODO : is 제외하고 저장하는 로직 구현해야 함.
      localStorage.setItem(this.dashboardLayoutKey, JSON.stringify(this.layout?.filter(item => item.is)));
    },
  },
};
</script>
