<template>
  <div :class="['f-calendar', !isReduced && 'reduce']">
    <div class="content-wrapper" v-if="imageURL">
      <div
        class="background"
        :style="{
          backgroundImage: `url(${imageURL})`,
        }"
      />
    </div>
    <div id="content-wrapper" class="content-wrapper">
      <div class="content">
        <ul class="header">
          <li
            :style="{ color: getDayColorValue(day) }"
            v-for="day in 7"
            :key="day"
          >
            {{ dayList[day - 1] }}
          </li>
        </ul>
        <div
          v-if="calendarList.length === 0"
          class="none"
        >
          <div class="caption">
            달력 데이터 불러오는 중...
          </div>
        </div>
        <ul
          class="rows"
          :style="{
            height: `calc((100% - 34px) / ${calendarList.length})`,
          }"
          v-for="(row, rowIndex) in calendarList"
          :key="rowIndex"
        >
          <li
            :class="isClose(column.bsnCode)
              ? [
                  isSameMonth(column.bsnDate) ? 'this-month close' : 'close',
                  isSameDay(column.bsnDate) ? 'this-day' : undefined,
                ]
              : [
                  isSameMonth(column.bsnDate) ? 'this-month' : undefined,
                  isSameDay(column.bsnDate) ? 'this-day' : undefined,
                  column.dwCode === '1' ? 'sunday' : undefined,
                  column.dwCode === '7' ? 'saturday' : undefined,
                ]
            "
            @click="isWeatherMode ? onClickWeatherPopup(column.bsnDate) : undefined"
            v-for="(column, columnIndex) in row"
            :key="columnIndex"
          >
            <div
              class="top"
              @click.self="isWeatherMode
                ? onClickWeatherPopup(column.bsnDate)
                : onClickDate(column.bsnDate)
              "
            >
              <div
                :class="[
                  'inner',
                  isToday(column.bsnDate) ? 'today' : undefined,
                ]"
                :style="{color: getDayColorValue(column.dwCode, column.bsnCode)}"
                @click="onClickReservItem(column.bsnDate)"
              >
                {{ getDay(column.bsnDate) }}
              </div>
              <div class="weather-label" >
                <span
                    class="weather-label-box"
                    v-if="((column.lwetTp !== null && column.lwetTp !== undefined) || (column.topTp !== null && column.topTp !== undefined))"
                    @click="onClickWeatherPopup(column.bsnDate)"
                >
                  <template v-if="column.lwetTp !== null || column.lwetTp !== undefined">
                    {{column.lwetTp}}
                    <div class="weather-informationUnit">℃</div>
                  </template>
                  <template v-if="(column.topTp !== null || undefined) && (column.lwetTp !== null || undefined)"> / </template>
                  <template v-if="column.topTp !== null || undefined">
                    {{column.topTp}}
                    <div class="weather-informationUnit">℃</div>
                  </template>
                </span>
              </div>
              <div
                :class="['weather', !column.weatherDiv && 'blank']"
                @click="onClickWeatherPopup(column.bsnDate)"
              >
                <img
                  v-if="column.weatherDiv"
                  :src="getWeather(column.weatherDiv)"
                  :alt="getWeatherLabel(column.weatherDiv)"
                />
                <span v-if="!column.weatherDiv">
                  ?
                </span>
              </div>
              <!-- <div
                  class="weatherLabel"
                  @click="onClickDate(column.bsnDate)"
                  v-if="column.weatherDiv"
              >
                {{ getWeatherLabel(column.weatherDiv) }}
              </div> -->
            </div>
            <div
              class="middle left"
              v-if="isWeatherMode && column.weatherDiv"
              @click="onClickWeatherPopup(column.bsnDate)"
            >
              <div class="item">
                <div class="row">· 최저기온: {{ column.lwetTp || 0 }}℃</div>
                <div class="row">· 최고기온: {{ column.topTp || 0 }}℃</div>
                <div class="row">· 일강수량: {{ column.prcptQy || 0 }}mm</div>
                <div class="row">· 일적설량: {{ column.snowdQy || 0 }}mm</div>
              </div>
            </div>
            <div
              :class="[
                'middle',
                contentHeight < 466 && 'none',
                !hasResveReceiptResveGet && 'hide'
              ]"
              v-if="!isWeatherMode"
              @click="onClickDate(column.bsnDate)"
            >
              <div class="item" style="margin-right: 4px; color: rgb(75, 137, 220)">
                <div :class="['row header', contentHeight < 666 && 'none']">총</div>
                <div :class="['row', contentHeight < 586 && 'none']">
                  {{
                    getTeamCount(
                      column.allTeamCountByDivision,
                      column.allTeamCount,
                    ) || 0
                  }}
                </div>
                <div class="row">{{ column.allTeamPercent || 0 }}%</div>
              </div>
              <div class="item" v-if="isUsePartDiv('1')">
                <div class="row header">1부</div>
                <div :class="['row', contentHeight < 586 && 'none']">
                  {{
                    getTeamCount(
                      column.partOneAllTeamCountByDivision,
                      column.partOneAllTeamCount,
                    ) || 0
                  }}
                </div>
                <div class="row">{{ column.partOneAllTeamPercent || 0 }}%</div>
              </div>
              <div class="item" v-if="isUsePartDiv('2')">
                <div class="row header">2부</div>
                <div :class="['row', contentHeight < 586 && 'none']">
                  {{
                    getTeamCount(
                      column.partTwoAllTeamCountByDivision,
                      column.partTwoAllTeamCount,
                    ) || 0
                  }}
                </div>
                <div class="row">{{ column.partTwoAllTeamPercent || 0 }}%</div>
              </div>
              <div class="item" v-if="isUsePartDiv('3')">
                <div class="row header">3부</div>
                <div :class="['row', contentHeight < 586 && 'none']">
                  {{
                    getTeamCount(
                      column.partThreeAllTeamCountByDivision,
                      column.partThreeAllTeamCount,
                    ) || 0
                  }}
                </div>
                <div class="row">
                  {{ column.partThreeAllTeamPercent || 0 }}%
                </div>
              </div>
            </div>
            <div class="close" v-if="!isWeatherMode">휴장</div>
            <div
              :class="[
                'bottom',
                column.allTeamCount > 0 && column.allTeamPercent === (searchDiv === 'RESERVATION' ? 100 : 0) ? 'clear' : undefined,
              ]"
              @click="isWeatherMode
                ? onClickWeatherPopup(column.bsnDate)
                : onClickDate(column.bsnDate)
              "
              v-if="!isWeatherMode"
            >
              <div
                class="holyday"
                :style="{
                  color: getDayColorValue(column.dwCode, column.bsnCode),
                }"
                v-if="!isWeatherMode"
              >
                {{ column.hldyName }}
              </div>
              <div
                class="reservation"
                v-if="column.allTeamCount > 0 && column.allTeamPercent === (searchDiv === 'RESERVATION' ? 100 : 0)"
              >
                예약 마감
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="sidebar">
        <div class="backdrop"/>
        <div class="rows">
          <div class="item prev" @click="onClickGoDate('prev')"/>
          <div class="item date-bar">
            <ejs-datepicker
              ref="datepicker"
              :value="currentDate"
              start="Year"
              depth="Year"
              format="yyyy년 M월"
              @change="onChangeCurrentDate"
            />
          </div>
          <div class="item today" @click="onClickGoDate('today')">오늘</div>
          <div class="item next" @click="onClickGoDate('next')"/>
        </div>
        <div class="rows">
          <div
            class="item bottom"
            :class="[mode === 1 && 'active']"
            :disabled="commonMixinIsButtonDisableByAuth('resveReceiptResveGet')"
            @click="onClickSearchDiv('RESERVATION')"
          >
            예약팀
          </div>
          <div
            class="item bottom"
            :class="[mode === 2 && 'active']"
            :disabled="commonMixinIsButtonDisableByAuth('resveReceiptResveGet')"
            @click="onClickSearchDiv('RESIDUAL')"
          >
            잔여팀
          </div>
          <div
            class="item bottom"
            :class="[mode === 3 && 'active']"
            :disabled="commonMixinIsButtonDisableByAuth('commonWeatherGet')"
            @click="onClickWeatherMode"
          >
            기상 정보
          </div>
        </div>
        <div class="rows">
          <div class="item refresh" @click="refresh">새로고침</div>
        </div>
        <div :class="['charts', contentHeight < 706 && 'none']">
          <div
            v-if=" mode !== 3"
            class="chart"
          >
            <div
              class="item"
              :style="{
                background: `conic-gradient(#F15F5F 0%, #F15F5F ${pureTodayReserveClosingRate}%, transparent ${pureTodayReserveClosingRate + .2}%, transparent 100%)`,
              }"
            >
              <div class="content">
                <div class="label">
                  {{ searchDiv === 'RESERVATION' ? `${moment(currentDate).format('M월 D일')} 예약율` : `${moment(currentDate).format('M월 D일')} 잔여율` }}
                </div>
                <div class="percent">
                  {{ pureTodayReserveClosingRate }} %
                </div>
              </div>
            </div>
          </div>
          <div
              v-if=" mode !== 3"
              class="chart"
          >
            <div
              class="item"
              :style="{
                background: `conic-gradient(#6799FF 0%, #6799FF ${pureMonthReserveClosingRate}%, transparent ${pureMonthReserveClosingRate + .2}%, transparent 100%)`,
              }"
            >
              <div class="content">
                <div class="label">
                  {{ searchDiv === 'RESERVATION' ? `${moment(currentDate).format('M월')} 예약율` : `${moment(currentDate).format('M월')} 잔여율` }}
                </div>
                <div class="percent">
                  {{ pureMonthReserveClosingRate }} %
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="pureTodayWeatherList && mode === 3"
            class="header"
          >
            {{ `${moment(currentDate).format('M월')} 기상 정보` }}
          </div>
          <div
            v-if="pureTodayWeatherList && mode === 3"
            class="chart"
            style="width: 200px; height: 200px; margin: 0;"
          >
            <div
              v-if="pieChartToComponent"
              :is="pieChartToComponent"
              :options="piChartOptions"
              :chartData="chartDataSource"
            />
          </div>
        </div>
        <div class="nowDate">
          <div class="number">
            {{ nowMonth }}
          </div>
        </div>
      </div>
    </div>
    <calendar-weather-popup
      v-if="isCalendarWeatherPopupOpen"
      ref="calendarWeatherPopup"
      @popupClosed="onCalendarWeatherPopupClosed"
    />
  </div>
</template>

<style scoped>
/* MAIN */
.f-calendar {height: calc(100% - 1rem);margin: 0.5rem;background-color: #fff;box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);font-family: NanumSquare-Regular, serif;cursor: default;}
.f-calendar > .content-wrapper {display: flex;flex-direction: row;border: 1px solid #ccc;}
.f-calendar > .content-wrapper > .background {width: 100%;height: auto;background-size: cover;filter: saturate(0.5);}
.f-calendar > .content-wrapper > .content {width: calc(100% - 220px);height:100%;border-right: 1px solid #ccc;}
.f-calendar > .content-wrapper > .content > .none {display: flex;align-items: flex-end;justify-content: flex-end;height: calc(100% - 30px);background-color: #fafafa}
.f-calendar > .content-wrapper > .content > .none > .caption {margin: 1rem 1.5rem;color: #333;font-family: NanumSquare-Regular, serif;font-size: 16px;}
.f-calendar > .content-wrapper > .content > ul {display: flex;margin: 0;padding: 0;list-style: none;}
.f-calendar > .content-wrapper > .content > ul:not(:last-child) {border-bottom: 1px solid #ccc;}
.f-calendar > .content-wrapper > .content > ul.header {height: 30px;border-bottom: 4px double #ccc;}
.f-calendar > .content-wrapper > .content > ul > li {width: calc(100% / 7);}
.f-calendar > .content-wrapper > .content > ul.header > li,
.f-calendar > .content-wrapper > .content > ul.rows > li > .bottom {display: flex;align-items: center;justify-content: center;}
.f-calendar > .content-wrapper > .content > ul > li:not(:last-child) {border-right: 1px solid #ccc;}
.f-calendar > .content-wrapper > .content > ul.rows > li {display: flex;flex-direction: column;cursor: pointer;}
.f-calendar > .content-wrapper > .content > ul.rows > li.sunday {background-color: rgba(200, 0, 0, .04);}
.f-calendar > .content-wrapper > .content > ul.rows > li.saturday {background-color: rgba(0, 149, 255, .04);}
.f-calendar > .content-wrapper > .content > ul.rows > li:hover {background-color: rgba(0, 0, 255, .02);}
.f-calendar > .content-wrapper > .content > ul.rows > li > .close,
.f-calendar > .content-wrapper > .content > ul.rows > li.close > .middle,
.f-calendar > .content-wrapper > .content > ul.rows > li.close > .bottom {display: none;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .middle.none {display: none;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .middle.hide {visibility: hidden;}
.f-calendar > .content-wrapper > .content > ul.rows > li.close {background-color: rgba(0, 0, 0, 0.02);cursor: default;}
.f-calendar > .content-wrapper > .content > ul.rows > li.close > .close {display: flex;align-items: center;justify-content: center;height: calc(100% - 74px);}
.f-calendar > .content-wrapper > .content > ul.rows > li > .top {display: flex;justify-content: space-between;position: relative;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .top > .weather {max-width: 100px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;cursor: pointer;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .top > .weather.blank {display: flex;align-items: center;justify-content: center;width: 26px;height: 26px;margin: 3px;border-radius: 500rem;background-color: #fff;box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .05);}
.f-calendar > .content-wrapper > .content > ul.rows > li > .top > .weather.blank:hover {box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .15);}
.f-calendar > .content-wrapper > .content > ul.rows > li > .top > .weather > img {width: 26px;height: 26px;margin: 3px;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .top > .weather > img:hover {scale: 1.25;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .top > .weatherLabel {width: calc(100% - 6px);position: absolute;top: 40px;left: 0;font-size: 13px;text-align: right;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .top > .inner {display: flex;justify-content: center;width: 32px;height: 32px;line-height: 32px;margin: 1px 0 0 1px;font-size: 13px;font-weight: bold;border-radius: 500rem; cursor: pointer;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .top > .inner.today {background-color: #2e7458;text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .top > .inner:hover {background-color: #fff;box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .05);}
.f-calendar > .content-wrapper > .content > ul.rows > li > .middle {display: flex;flex: 1;justify-content: center;} /* 날씨 라벨 추가시 => margin-top: 1rem */
.f-calendar > .content-wrapper > .content > ul.rows > li > .middle.left {flex: none;justify-content: flex-start;padding-left: 0.5rem;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .middle > .item {display: flex;flex-direction: column;align-items: center;justify-content: center;width: 40px;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .middle.left > .item {align-items: flex-start;width: auto;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .middle > .item > .row {display: flex;align-items: center;height: 21px;text-align: center;overflow: hidden;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .middle > .item > .row.none {display: none;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .middle > .item > .row.header {font-weight: bold;opacity: 0.1;}
.f-calendar > .content-wrapper > .content > ul.rows > li:hover > .middle > .item > .row.header {opacity: 1;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .bottom {display: flex;justify-content: left;height: 24px;padding: 0 0.5rem;background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .02));}
.f-calendar > .content-wrapper > .content > ul.rows > li > .bottom > .reservation {display: flex;flex: 1;justify-content: flex-end;font-weight: bold;}
.f-calendar > .content-wrapper > .content > ul.rows > li > .bottom.clear {background: linear-gradient(to bottom, transparent, rgba(0, 0, 255, .06));}
.f-calendar > .content-wrapper > .content > ul.rows > li:not(.this-month) {filter: grayscale(1) opacity(0.75);}
.f-calendar > .content-wrapper > .content > ul.rows > li:not(.this-month) > .top > .inner {font-size: 12px;font-weight: unset;}
.f-calendar > .content-wrapper > .content > ul.rows > li.this-day {box-shadow: 0 0 0 3px #2e7458 inset;}

.f-calendar.reduce > .content-wrapper > .content > ul.rows > li > .middle > .item:not(:first-child) {display: none}
/*.f-calendar.reduce > .content-wrapper > .content > ul.rows > li > .middle > .item {display: none}*/

/* SIDEBAR */
.f-calendar > .content-wrapper > .sidebar {position: relative;width: 200px;padding: 10px;}
.f-calendar > .content-wrapper > .sidebar > .backdrop {position: absolute;left: 0;bottom: 0;width: 100%;height: 70%;background: linear-gradient(to bottom, #0000, #00000010);}
.f-calendar > .content-wrapper > .sidebar > .rows {display: flex;z-index: 1}
.f-calendar > .content-wrapper > .sidebar > .rows > .item {display: flex;align-items: center;justify-content: center;width: 29px;height: 30px;cursor: pointer;}
.f-calendar > .content-wrapper > .sidebar > .rows > .item.prev {border-top-left-radius: 5px;background: url(../../../assets/images/common/accordion-left.png) no-repeat center;}
.f-calendar > .content-wrapper > .sidebar > .rows > .item.next {border-top-right-radius: 5px;background: url(../../../assets/images/common/accordion-right.png) no-repeat center;}
.f-calendar > .content-wrapper > .sidebar > .rows > .item:hover {border-radius: 5px;background: rgba(0, 0, 0, 0.04);}
.f-calendar > .content-wrapper > .sidebar > .rows > .item.prev:hover {background: rgba(0, 0, 0, 0.04) url(../../../assets/images/common/accordion-left-black.png) no-repeat center;}
.f-calendar > .content-wrapper > .sidebar > .rows > .item.next:hover {background: rgba(0, 0, 0, 0.04) url(../../../assets/images/common/accordion-right-black.png) no-repeat center;}
.f-calendar > .content-wrapper > .sidebar > .rows > .item.date-bar {flex: 1;}
.f-calendar > .content-wrapper > .sidebar > .rows > .item.today {width: 40px;}
.f-calendar > .content-wrapper > .sidebar > .rows > .item.refresh {width: 100%;margin-top: .5rem;padding: 2px 0 3px;color: #1f5b41;font-size: 13px;font-weight: bold;border: 1px solid #ccc;border-bottom-width: 3px;border-radius: 5px;background: url(../../../assets/images/common/refresh.png) no-repeat 5px;background-size: 24px;box-shadow: 0 6px 6px 0 rgba(0, 0, 0, .06);}
.f-calendar > .content-wrapper > .sidebar > .rows > .item.bottom {width: 50%;margin-top: .5rem;border: 1px solid #ccc;border-radius: 3px;background-color: #fff;font-size: 12px;box-shadow: 0 6px 6px 0 rgba(0, 0, 0, .06);}
.f-calendar > .content-wrapper > .sidebar > .rows > .item.bottom:hover,
.f-calendar > .content-wrapper > .sidebar > .rows > .item.refresh:hover {background-color: #fafafa;}
.f-calendar > .content-wrapper > .sidebar > .rows > .item.bottom.active {color: #fff;font-weight: bold;border-color: #2e7458;background-color: #2e7458;}
.f-calendar > .content-wrapper > .sidebar > .rows > .item.bottom.active:hover {border-color: #1f5b41;background-color: #1f5b41;}
.f-calendar > .content-wrapper > .sidebar > .rows > .item.bottom:not(:last-child) {margin-right: 5px;}
.f-calendar > .content-wrapper > .sidebar > .rows > .item.bottom[disabled="disabled"] {pointer-events: none;opacity: .6;}
.f-calendar > .content-wrapper > .sidebar > .rows > .item > .e-input-group {position: relative;}
.f-calendar > .content-wrapper > .sidebar > .rows > .item > .e-input-group > input {margin-top: 3px;padding: 0;font-size: 15px;font-family: NanumSquare-Regular, serif;font-weight: bold;text-align: center;caret-color: transparent;pointer-events: none;}
.f-calendar > .content-wrapper > .sidebar > .rows > .item > .e-input-group > input::selection {color: #FFF; background-color: #3390FF;}
.f-calendar > .content-wrapper > .sidebar > .charts {display: flex;flex-direction: column;margin-top: .5rem;overflow: hidden}
.f-calendar > .content-wrapper > .sidebar > .charts.none {display: none}
.f-calendar > .content-wrapper > .sidebar > .charts > .header {display: flex;align-items: center;justify-content: center;margin-top: 1rem;font-size: 14px}
.f-calendar > .content-wrapper > .sidebar > .charts > .chart {position: relative;width: 160px;height: 160px;margin: 20px}
.f-calendar > .content-wrapper > .sidebar > .charts > .chart > .item {position: absolute;width: inherit;height: inherit;border-radius: 500rem;transition: .3s;box-shadow: 0 6px 6px 0 rgba(0, 0, 0, .04);}
.f-calendar > .content-wrapper > .sidebar > .charts > .chart > .item > .content {display: flex;flex-direction: column;align-items: center;justify-content: center;position: absolute;top: 50%;left: 50%;width: 120px;height: 120px;text-align: center;border-radius: 500rem;background: rgba(255, 255, 255, .9);backdrop-filter: blur(20px);transform: translate(-50%, -50%);z-index: 2;}
.f-calendar > .content-wrapper > .sidebar > .charts > .chart > .item > .content > .label {font-size: 13px;text-align: center}
.f-calendar > .content-wrapper > .sidebar > .charts > .chart > .item > .content > .percent {font-size: 18px;font-weight: bold}
.f-calendar > .content-wrapper > .sidebar > .charts > .chart > .item > .value {display: flex;flex-direction: column;align-items: flex-end;justify-content: flex-end;width: inherit;height: inherit;margin-left: -80px;margin-top: -80px;color: #000;z-index: 100;}
.f-calendar > .content-wrapper > .sidebar > .charts > .chart > .item > .value > .text {padding-right: 20px}
.f-calendar > .content-wrapper > .sidebar > .nowDate {position: absolute;bottom: .5rem;right: 1.5rem;color: #000}
.f-calendar > .content-wrapper > .sidebar > .nowDate > .number {position: absolute;right: -.5rem;bottom: -8rem;font-size: 16rem;font-weight: bold;letter-spacing: -3rem;opacity: .75;}

/* 최고 최저 기온 */
.f-calendar > .content-wrapper > .content > ul.rows > li > .top > .weather-label{
  display: flex;
  justify-content: center;
  height: 32px;
  line-height: 32px;
  margin: 1px 0 0 1px;
  font-size: 13px;
  border-radius: 500rem;
  color :#2e7458;
}
.f-calendar > .content-wrapper > .content > ul.rows > li > .top > .weather-label .weather-label-box{
  display: flex; cursor: pointer;
}
.f-calendar > .content-wrapper > .content > ul.rows > li > .top > .weather-label .weather-label-box:hover {
  text-shadow: 0 0 3px;
}
.f-calendar > .content-wrapper > .content > ul.rows > li > .top > .weather-label .weather-informationUnit{
  flex: 0 0 auto;
  font: normal normal 100 5px / 1.5 'Noto Sans KR';
  padding: 8px 2px 0px 2px;
}
</style>

<script>
import moment from 'moment';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodesGetCodeDesc,
} from '@/utils/commonCodes';
import { getDayColorValue } from '@/utils/date';
import CalendarWeatherPopup from '@/views/golf-reservation/popup/CalendarWeatherPopup';
import commonMixin from '@/views/layout/mixin/commonMixin';
import {DATE_FORMAT_YYYY_MM_DD} from '@/utils/date';
import PieChart from "@/utils/charts/PieChart";
import {getCalendarList} from "@/api/calendar";

export default {
  name: 'Calendar',
  mixins: [commonMixin],
  components: {
    CalendarWeatherPopup,
    PieChart,
  },
  props: {
    uuid: {
      type: Number,
      default: 0,
    },
    isReduced: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      weather: null,
      currentDate: new Date(),
      thisMonth: new Date(),
      thisDay: null,
      searchDiv: 'RESERVATION',
      mode: 1,
      dayList: ['일', '월', '화', '수', '목', '금', '토'],
      partDivList: commonCodesGetCommonCode('PART_DIV'),
      pureCalendarList: [],
      calendarList: [],
      weatherList: [],
      imageURL: null,
      contentHeight: 0,
      isAditTeam: false,
      isWeatherMode: false,
      pureTodayReserveClosingRate: null,
      pureMonthReserveClosingRate: null,
      funcTodayReserveClosingRate: null,
      funcMonthReserveClosingRate: null,
      pureTodayWeatherList: null,
      isCalendarWeatherPopupOpen: false,
      piChartOptions: {
        responsive: true,
      }
    };
  },
  watch: {
    async uuid() {
      await this.$nextTick();
      this.resize();
    }
  },
  async mounted() {
    this.isWeatherMode = !this.hasResveReceiptResveGet && this.hasCommonWeatherGet;
    if (this.commonMixinIsButtonDisableByAuth('commonDashboardGet')) {
      const info = sessionStorage.getItem('Standard-Infos');
      this.imageURL = JSON.parse(info)?.imagePrintURL;
    } else {
      await this.refresh();
      await this.$nextTick();
      this.resize();
      let self = this;
      window.onresize = () => self.resize();
    }
  },
  computed: {
    pieChartToComponent() {
      return PieChart;
    },
    chartDataSource() {
      return {
        labels: this.pureTodayWeatherList.map(data => data.comName),
        datasets: [
          {
            data: this.pureTodayWeatherList.map(data => data.count),
            backgroundColor: this.pureTodayWeatherList.map(data => data.colorValue),
          }
        ]
      };
    },
    hasResveReceiptResveGet() {
      return !this.commonMixinIsButtonDisableByAuth('resveReceiptResveGet');
    },
    hasCommonWeatherGet() {
      return !this.commonMixinIsButtonDisableByAuth('commonWeatherGet');
    },
    nowMonth() {
      return moment(this.currentDate).locale('en').format('M');
    },
    monthlyCalendarList() {
      return this.pureCalendarList
        ?.filter(item => this.isSameMonth(item.bsnDate));
    },
    todayReserveClosingRate() {
      const data = this.pureCalendarList.length > 0
        ? this.pureCalendarList
          ?.find(item => item.bsnDate === moment(this.currentDate).format(DATE_FORMAT_YYYY_MM_DD))
        : null;
      return data
        ? data.allTeamPercent
        : null;
    },
    monthReserveClosingRate() {
      const filteredList = this.monthlyCalendarList?.filter(({bsnCode}) => bsnCode !== "CLOSE");
      const min = filteredList?.reduce((acc, cur) => acc + cur.allTeamCountByDivision, 0);
      const max = filteredList?.reduce((acc, cur) => acc + cur.allTeamCount, 0);
      return max > 0
        ? Math.round((min / max) * 100)
        : 0;
    },
  },
  methods: {
    moment,
    getDayColorValue,
    drawReserveClosingRate() {
      this.pureTodayReserveClosingRate = 0;
      if (this.todayReserveClosingRate) {
        let i = 1;
        if (this.funcTodayReserveClosingRate) {
          clearInterval(this.funcTodayReserveClosingRate);
          this.funcTodayReserveClosingRate = null;
        }
        this.funcTodayReserveClosingRate = setInterval(() => {
          if (i <= this.todayReserveClosingRate) {
            this.pureTodayReserveClosingRate = i++;
          } else {
            clearInterval(this.funcTodayReserveClosingRate);
          }
        }, 5);
      }
      this.pureMonthReserveClosingRate = 0;
      if (this.monthReserveClosingRate) {
        let i = 1;
        if (this.funcMonthReserveClosingRate) {
          clearInterval(this.funcMonthReserveClosingRate);
          this.funcMonthReserveClosingRate = null;
        }
        this.funcMonthReserveClosingRate = setInterval(() => {
          if (i <= this.monthReserveClosingRate) {
            this.pureMonthReserveClosingRate = i++;
          } else {
            clearInterval(this.funcMonthReserveClosingRate);
          }
        }, 5);
      }
      this.pureTodayWeatherList = null;
      if (this.weatherList.length > 0) {
        const color = [
          '#F15F5F',
          '#F29661',
          '#F2CB61',
          '#BCE55C',
          '#6799FF',
          '#A566FF',
        ];
        const month = Number(moment(this.currentDate).format('MM'));
        const monthItem = this.weatherList
          ?.filter(item => item.month === month);
        const sum = monthItem
          ?.reduce((acc, cur) => acc + cur.count, 0);
          if (monthItem.length > 0) {
            let rotateCount = 0;
            let perCount = 0;
            this.pureTodayWeatherList = monthItem
              ?.map((item, index) => {
                const rotate = Math.floor((item.count / sum) * 360);
                const per = Math.floor((item.count / sum) * 100);
                item.rotate = ((rotateCount + (rotateCount + rotate)) / 2) + 90;
                item.min = perCount;
                item.max = perCount + per;
                item.colorValue = color[index];
                rotateCount += rotate;
                perCount += per;
                return item;
              });
          }
      }
    },
    getDay(day) {
      const m = moment(day).format('M');
      const d = moment(day).format('D');
      return d === '1' ? `${m}/${d}` : d;
    },
    getTeamCount(countByDiv, count) {
      return this.searchDiv === 'RESERVATION'
        ? `${countByDiv}/${count}`
        : countByDiv;
    },
    getWeather(weatherDiv) {
      const weather = commonCodesGetCodeDesc('WEATHER_CODE', weatherDiv);
      return weather && require(`../../../assets/images/weather/${weather}`);
    },
    getWeatherLabel(weatherDiv) {
      return commonCodesGetComName('WEATHER_CODE', weatherDiv);
    },
    isSameMonth(day) {
      return moment(this.thisMonth).format('M') === moment(day).format('M');
    },
    isSameDay(day) {
      return (
        moment(this.thisDay).format('YYYY.MM.DD') ===
        moment(day).format('YYYY.MM.DD')
      );
    },
    isToday(day) {
      return moment().format('YYYY.MM.DD') === moment(day).format('YYYY.MM.DD');
    },
    isClose(bsnCode) {
      return bsnCode === 'CLOSE';
    },
    isUsePartDiv(partDiv) {
      return this.partDivList.find((item) => item.comCode === partDiv)?.useFlag;
    },
    async onChangeCurrentDate(args) {
      this.currentDate = args.value;
      this.thisMonth = args.value;
      await this.refresh();
    },
    onClickSearchDiv(searchDiv) {
      this.searchDiv = searchDiv;
      this.mode = searchDiv === 'RESERVATION' ? 1 : 2;
      this.isWeatherMode = false;
      this.refresh();
    },
    onClickWeatherMode() {
      this.isWeatherMode = true;
      this.mode = 3;
    },
    onClickReservItem(day) {
      if (!this.hasResveReceiptResveGet) {
        return;
      }
      this.$router.push({
        name: 'reservationReceiptRegistration',
        query: {
          bsnDate: day,
        },
      });
    },
    onClickDate(day) {
      this.currentDate = moment(day).format();
      this.thisDay = day;
      this.drawReserveClosingRate();
    },
    onClickWeatherPopup(day) {
      if (
        this.isCalendarWeatherPopupOpen ||
        this.commonMixinIsButtonDisableByAuth('commonWeatherGet')
      ) {
        return;
      }
      this.thisDay = day;
      this.isCalendarWeatherPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.calendarWeatherPopup.showPopup(day);
      });
    },
    onClickGoDate(time) {
      switch (time) {
        case 'prev':
          this.currentDate = moment(this.currentDate).add(-1, 'M').format();
          this.thisDay = moment(this.currentDate).format(DATE_FORMAT_YYYY_MM_DD);
          break;
        case 'next':
          this.currentDate = moment(this.currentDate).add(1, 'M').format();
          this.thisDay = moment(this.currentDate).format(DATE_FORMAT_YYYY_MM_DD);
          break;
        case 'today':
          this.currentDate = moment().format();
          this.thisDay = moment().format(DATE_FORMAT_YYYY_MM_DD);
          this.drawReserveClosingRate();
          break;
      }
    },
    async onCalendarWeatherPopupClosed() {
      this.isCalendarWeatherPopupOpen = false;
      await this.refresh();
    },
    resize() {
      const contentWrapper = document.getElementById('content-wrapper');
      this.contentHeight = contentWrapper?.getBoundingClientRect()?.height;
    },
    async refresh() {
      const currentDate = moment(this.currentDate);
      const year = currentDate.format('YYYY');
      const month = currentDate.format('MM');
      const searchOptions = {
        year,
        month,
        div: this.searchDiv,
        isAditTeam: this.isAditTeam,
      };
      this.pureCalendarList = [];
      this.calendarList = [];
      const calendarList = await GolfErpAPI.fetchReservationCalendar(searchOptions);
      const {value: {calendarList : weatherList}} = await getCalendarList(year, month);
      let calendarBlock = [];
      calendarList.map((item, index) => {
        calendarBlock.push(item);
        if (index % 7 === 6 || index === calendarList.length - 1) {
          this.calendarList.push(calendarBlock);
          calendarBlock = [];
        }
      });
      /**
       * 최저,최고기온
       */
      weatherList.forEach((v) => {
        calendarList.forEach((_v) => {
          if(v.bsnDate === _v.bsnDate){
            _v.lwetTp = v.lwetTp;
            _v.topTp = v.topTp;
          }
        });
      });
      this.pureCalendarList = calendarList.map((data) => {
        if(data.lwetTp === null || data.lwetTp === undefined) {
          data.lwetTp = null;
        }
        if(data.topTp === null || data.topTp === undefined) {
          data.topTp = null;
        }
        return data;
      });
      this.weatherList = await GolfErpAPI.fetchReservationCalendarWeathers({
        year: Number(year),
        month: Number(month),
      });
      this.drawReserveClosingRate();
    },
  },
};
</script>
