<template>
  <div
    :id="elementId"
    ref="component"
    class="chart-wrapper"
  >
    <line-chart
      :options="options"
      :styles="{height: `${contentHeight}px`}"
      :chart-data="chartDataSource"
    />
  </div>
</template>

<style scoped>
.chart-wrapper {height: 100%}
</style>

<script>
import moment from 'moment';
import {numberWithCommas} from '@/utils/number';
import LineChart from '@/utils/charts/LineChart.js';

export default {
  name: 'chart1',
  components: {LineChart},
  props: {
    i: {
      type: Number,
      default: 0,
    },
    uuid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      contentHeight: 0,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        hover: {animationDuration: 0},
        animation: {
          duration: 1,
          onComplete: function() {
            const chartInstance = this.chart
                , ctx = chartInstance.ctx
                , legendItems = chartInstance.legend.legendItems;
            ctx.fillStyle = '#333';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            this.data.datasets.forEach(function(dataset, i) {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                const data = dataset.data[index];
                if (data > 0 && !legendItems[i].hidden) {
                  ctx.fillText(numberWithCommas(data), bar._model.x, bar._model.y - 5);
                }
              });
            });
          },
        },
      },
    };
  },
  watch: {
    async uuid() {
      await this.$nextTick();
      this.resize();
    }
  },
  async mounted() {
    await this.$nextTick();
    this.resize();
    let self = this;
    window.onresize = () => self.resize();
  },
  computed: {
    elementId() {
      return `content-wrapper-${this.i}`;
    },
    chartDataSource() {
      const labels = [
        {
          label: '1부',
          color: '#F15F5F',
        },
        {
          label: '2부',
          color: '#47C83E',
        },
        {
          label: '3부',
          color: '#6799FF',
        },
      ];
      const dummyData = [
        [
          {
            value: 11,
            date: '2021-01-01',
          },
          {
            value: 6,
            date: '2021-01-02',
          },
          {
            value: 8,
            date: '2021-01-03',
          },
          {
            value: 4,
            date: '2021-01-04',
          },
          {
            value: 7,
            date: '2021-01-05',
          },
          {
            value: 12,
            date: '2021-01-06',
          },
          {
            value: 6,
            date: '2021-01-07',
          },
        ], [
          {
            value: 5,
            date: '2021-01-01',
          },
          {
            value: 7,
            date: '2021-01-02',
          },
          {
            value: 6,
            date: '2021-01-03',
          },
          {
            value: 2,
            date: '2021-01-04',
          },
          {
            value: 4,
            date: '2021-01-05',
          },
          {
            value: 9,
            date: '2021-01-06',
          },
          {
            value: 8,
            date: '2021-01-07',
          },
        ], [
          {
            value: 7,
            date: '2021-01-01',
          },
          {
            value: 1,
            date: '2021-01-02',
          },
          {
            value: 3,
            date: '2021-01-03',
          },
          {
            value: 6,
            date: '2021-01-04',
          },
          {
            value: 5,
            date: '2021-01-05',
          },
          {
            value: 8,
            date: '2021-01-06',
          },
          {
            value: 9,
            date: '2021-01-07',
          },
        ],
      ];
      const datasets = labels?.map((data, index) => ({
        data: dummyData[index]?.map(item => item.value),
        label: data.label,
        borderWidth: 1,
        borderColor: data.color,
        backgroundColor: data.color,
        tension: 0,
        fill: false,
      }));
      return {
        labels: dummyData[0]?.map(item => moment(item.date).format('MM-DD')),
        datasets,
      };
    },
  },
  methods: {
    resize() {
      const contentWrapper = document.getElementById(this.elementId);
      this.contentHeight = contentWrapper?.getBoundingClientRect()?.height;
    },
  },
};
</script>
